import hankIcon from "./images/hank/icon.png";
import hankMain from "./images/hank/main.png";
import hankSkill1 from "./images/hank/skill1.png";
import hankSkill1Main from "./images/hank/skill1-main.png";
import hankSkill2 from "./images/hank/skill2.png";
import hankSkill3 from "./images/hank/skill3.png";
import hankSkill4 from "./images/hank/skill4.png";
// knives
import knivesIcon from "./images/knives/icon.png";
import knivesMain from "./images/knives/main.png";
// barata
import barataIcon from "./images/barata/icon.png";
import barataMain from "./images/barata/main.png";
// lagarto
import lagartoIcon from "./images/lagarto/icon.png";
import lagartoMain from "./images/lagarto/main.png";
// veia
import veiaIcon from "./images/veia/icon.png";
import veiaMain from "./images/veia/main.png";
import { css, SerializedStyles } from "@emotion/react";

type Character = {
  icon: string; //remover
  image: string; //remover
  imageCss: SerializedStyles;
  slug: string;
  name: string;
  role: string;
  description: string;
  skills: {
    icon: string; //remover
    image: string; //remover
    title: string;
    description: string;
  }[];
};

export const characters: Character[] = [
  // hank
  {
    slug: "hank",
    name: "Hank",
    role: "Dealer",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo.",
    icon: hankIcon,
    image: hankMain,
    imageCss: css`
      top: 50px;
      left: 550px;
    `,
    skills: [
      {
        title: "Q - Skill Name",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
        icon: hankSkill1,
        image: hankSkill1Main,
      },
      {
        title: "E - Skill Name",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
        icon: hankSkill2,
        image: hankSkill2,
      },
      {
        title: "C - Skill Name",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
        icon: hankSkill3,
        image: hankSkill3,
      },
      {
        title: "X - Skill Name",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
        icon: hankSkill4,
        image: hankSkill4,
      },
    ],
  },
  // knives
  {
    slug: "knives",
    name: "Knives",
    role: "Char Role",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo.",
    icon: knivesIcon,
    image: knivesMain,
    imageCss: css`
      top: 60px;
      left: 590px;
    `,
    skills: [
      {
        title: "Q - Skill Name",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
        icon: hankSkill1,
        image: hankSkill1Main,
      },
      {
        title: "E - Skill Name",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
        icon: hankSkill2,
        image: hankSkill2,
      },
      {
        title: "C - Skill Name",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
        icon: hankSkill3,
        image: hankSkill3,
      },
      {
        title: "X - Skill Name",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
        icon: hankSkill4,
        image: hankSkill4,
      },
    ],
  },
  // barata
  {
    slug: "barata",
    name: "Char Name",
    role: "Char Role",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo.",
    icon: barataIcon,
    image: barataMain,
    imageCss: css`
      top: 30px;
      left: 610px;
    `,
    skills: [
      {
        title: "Q - Skill Name",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
        icon: hankSkill1,
        image: hankSkill1Main,
      },
      {
        title: "E - Skill Name",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
        icon: hankSkill2,
        image: hankSkill2,
      },
      {
        title: "C - Skill Name",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
        icon: hankSkill3,
        image: hankSkill3,
      },
      {
        title: "X - Skill Name",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
        icon: hankSkill4,
        image: hankSkill4,
      },
    ],
  },
  // lagarto
  {
    slug: "lagarto",
    name: "Char Name",
    role: "Char Role",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo.",
    icon: lagartoIcon,
    image: lagartoMain,
    imageCss: css`
      left: 585px;
    `,
    skills: [
      {
        title: "Q - Skill Name",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
        icon: hankSkill1,
        image: hankSkill1Main,
      },
      {
        title: "E - Skill Name",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
        icon: hankSkill2,
        image: hankSkill2,
      },
      {
        title: "C - Skill Name",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
        icon: hankSkill3,
        image: hankSkill3,
      },
      {
        title: "X - Skill Name",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
        icon: hankSkill4,
        image: hankSkill4,
      },
    ],
  },
  // veia
  {
    slug: "veia",
    name: "Char Name",
    role: "Char Role",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo.",
    icon: veiaIcon,
    image: veiaMain,
    imageCss: css`
      top: 40px;
      left: 450px;
    `,
    skills: [
      {
        title: "Q - Skill Name",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
        icon: hankSkill1,
        image: hankSkill1Main,
      },
      {
        title: "E - Skill Name",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
        icon: hankSkill2,
        image: hankSkill2,
      },
      {
        title: "C - Skill Name",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
        icon: hankSkill3,
        image: hankSkill3,
      },
      {
        title: "X - Skill Name",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
        icon: hankSkill4,
        image: hankSkill4,
      },
    ],
  },
];
