import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { HomePage } from "./pages/home";
import { SignInPage } from "./pages/signin";
import { GalleryPage } from "./pages/gallery";
import { KnowMorePage } from "./pages/know-more";
import { RoadmapPage } from "./pages/roadmap";
import { CharactersPage } from "./pages/characters";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/signin" element={<SignInPage />} />
        <Route path="/galeria" element={<GalleryPage />} />
        <Route path="/saiba" element={<KnowMorePage />} />
        <Route path="/roadmap" element={<RoadmapPage />} />
        <Route path="/characters" element={<CharactersPage />} />
      </Routes>
    </Router>
  );
}

export default App;
