/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import "react-responsive-modal/styles.css";
import "./components/registerModal.css";
import { Fragment, useCallback, useEffect, useState } from "react";
import { Layout } from "../../components/Layout/Layout";
import { theme } from "../../theme/theme";
import { Button } from "../../components/Button/Button";
import bgLeft from "./images/bg-left.png";
import bgRight from "./images/bg-right.png";
import { RegisterModal } from "./components/RegisterModal";
import { useInternalGoogleLogin } from "../../hooks/useGoogleLogin";
import { Helmet } from "react-helmet";

export const SignInPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [status, setStatus] = useState<"success" | "error">("error");
  const form = document.getElementById("sign-form");

  const { login, logout } = useInternalGoogleLogin({
    onError: () => {
      setStatus("error");
      setIsModalOpen(true);
    },
    onSuccess: () => {
      setStatus("success");
      setIsModalOpen(true);
    },
    onFinally: () => {
      logout();
    },
  });

  const onFormSubmit = useCallback(
    (e: any) => {
      e.preventDefault();
      (form?.querySelector('[name="email"]') as any).value = "";
      // const data = new FormData(e.target);
      // const dataObject = Object.fromEntries(data.entries());
      // email to save: dataObject.email
      // console.log(dataObject.email);
      setStatus("success");
      setIsModalOpen(true);
    },
    [form]
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (form) {
      form.addEventListener("submit", onFormSubmit);
    }
  }, [form, onFormSubmit]);

  return (
    <Fragment>
      <Helmet title="Registre-se / Knives" defaultTitle="Knives" />
      <Layout showGalleryButton showMenuLinks={false}>
        <div css={styles.container}>
          <h1 css={styles.heading}>
            Olha quem
            <br />
            apareceu na cidade!
          </h1>
          <p css={styles.lead}>
            Registre seu email para ficar ligado nas novidades, e nos marque nas
            redes sociais!
          </p>

          <Button
            kind="primary"
            size={"md"}
            css={[styles.socialButtons, styles.googleButton]}
            onClick={() => login()}
          >
            Registrar com o Google
          </Button>

          <div css={styles.orDivider}>ou</div>

          <form css={styles.form} id="sign-form">
            <input
              name="email"
              type="email"
              placeholder="Registre com seu e-mail"
              css={styles.input}
              required
            />
            <Button kind="primary" size="md" type="submit">
              Registrar
            </Button>
          </form>

          <img
            src={bgLeft}
            css={styles.bgLeft}
            alt="Alguns personagens do Knives "
          />
          <img
            src={bgRight}
            css={styles.bgRight}
            alt="Alguns personagens do Knives "
          />

          <RegisterModal
            isOpen={isModalOpen}
            type={status}
            onClose={() => setIsModalOpen(false)}
          />
        </div>
      </Layout>
    </Fragment>
  );
};

const styles = {
  container: css`
    background: ${theme.pallete.surface.background};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 40px;
    padding-bottom: 64px;
    position: relative;
  `,
  bgLeft: css`
    position: absolute;
    left: 0;
    bottom: 0;
    @media screen and (max-width: 1024px) {
      display: none;
    }
  `,
  bgRight: css`
    position: absolute;
    right: 0;
    bottom: 0;
    @media screen and (max-width: 1024px) {
      display: none;
    }
  `,
  heading: css`
    color: ${theme.pallete.surface.white};
    font-size: 56px;
    text-align: center;
    margin-bottom: 16px;
  `,
  lead: css`
    color: ${theme.pallete.surface.white};
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    margin-bottom: 56px;
  `,
  socialButtons: css`
    width: 370px;
    margin-bottom: 16px;
    &:last-of-type {
      margin-bottom: 24px;
    }
  `,
  googleButton: css`
    color: ${theme.pallete.surface.white};
    background: #cf4332;
    &:hover {
      background: #a63729;
    }
  `,
  facebookButton: css`
    color: ${theme.pallete.surface.white};
    background: #3c66c4;
    &:hover {
      background: #2b4a8f;
    }
  `,
  appleButton: css`
    color: ${theme.pallete.surface.background};
    background: white;
    &:hover {
      background: #dedcdc;
    }
  `,
  orDivider: css`
    color: ${theme.pallete.surface.white};
    font-style: italic;
    margin-bottom: 24px;
  `,
  form: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `,
  input: css`
    color: ${theme.pallete.surface.white};
    font-size: 15px;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 370px;
    border: 1px solid white;
    border-radius: 16px;
    padding: 0 16px;
    margin-bottom: 16px;
    &::placeholder {
      color: #d1d1d1;
      font-style: italic;
      font-size: 15px;
    }
  `,
  submitButton: css`
    color: ${theme.pallete.surface.white};
    background-color: ${theme.pallete.primary.main};
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    border: 0;
    border-radius: 16px;
    padding: 0 24px;
  `,
};
