import { Fragment, useEffect } from "react";
import { Gallery } from "./Gallery";
import { Layout } from "../../components/Layout/Layout";
import { Helmet } from "react-helmet";

export const GalleryPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Fragment>
      <Helmet title="Galeria / Knives" defaultTitle="Knives" />
      <Layout showSignInButton showMenuLinks={false}>
        <div className="app">
          <Gallery />
        </div>
      </Layout>
    </Fragment>
  );
};
