import React from "react";
import { animateScroll as scroll } from "react-scroll";
import { FaInstagram, FaYoutube, FaTwitter } from "react-icons/fa";
import {
  FooterContainer,
  FooterWrap,
  FooterLinksContainer,
  FooterLinksWrapper,
  FooterLinkItems,
  FooterLinkTitle,
  FooterLink,
  SocialMedia,
  SocialMediaWrap,
  SocialLogo,
  WebsiteRights,
  SocialIcons,
  SocialIconLink,
  FooterLogoContainer,
  FooterLogo,
  LinkLogo,
} from "./FooterElements";
import { flags } from "../../flags/flags";

export const Footer = () => {
  const logoSrc = require("../../images/logob.svg").default;

  const toggleHome = () => {
    scroll.scrollToTop();
  };

  return (
    <FooterContainer>
      <FooterWrap>
        <FooterLinksContainer>
          <FooterLinksWrapper>
            {flags.CONTENT_NOT_FINISHED ? (
              <FooterLogoContainer>
                <LinkLogo to="https://therotfather.com/">
                  <FooterLogo
                    src={logoSrc}
                    alt="Logo Rotfather"
                    href="/"
                    target="_blank"
                    aria-label="Facebook"
                  />
                </LinkLogo>
              </FooterLogoContainer>
            ) : (
              <LinkLogo to="https://therotfather.com/">
                <FooterLogo
                  src={logoSrc}
                  alt="Logo Rotfather"
                  href="/"
                  target="_blank"
                  aria-label="Facebook"
                />
              </LinkLogo>
            )}
            {flags.CONTENT_NOT_FINISHED && (
              <FooterLinkItems>
                <FooterLinkTitle>Links úteis</FooterLinkTitle>
                <FooterLink to="/signin">Sobre</FooterLink>
                <FooterLink to="/signin">Contato</FooterLink>
                <FooterLink to="/signin">Suporte</FooterLink>
                <FooterLink to="/signin">Termos de Uso</FooterLink>
              </FooterLinkItems>
            )}
          </FooterLinksWrapper>
        </FooterLinksContainer>
        <SocialMedia>
          <SocialMediaWrap>
            <SocialLogo to="/" onClick={toggleHome}>
              Knives
            </SocialLogo>
            <WebsiteRights>
              Knives © {new Date().getFullYear()} All rights reserved.
            </WebsiteRights>
            {flags.CONTENT_NOT_FINISHED && (
              <SocialIcons>
                <SocialIconLink href="/" target="_blank" aria-label="Instagram">
                  <FaInstagram />
                </SocialIconLink>
                <SocialIconLink href="/" target="_blank" aria-label="Youtube">
                  <FaYoutube />
                </SocialIconLink>
                <SocialIconLink href="/" target="_blank" aria-label="Twitter">
                  <FaTwitter />
                </SocialIconLink>
              </SocialIcons>
            )}
          </SocialMediaWrap>
        </SocialMedia>
      </FooterWrap>
    </FooterContainer>
  );
};
