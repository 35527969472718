/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useMemo } from "react";
import { theme } from "../../theme/theme";

type ButtonKinds = "primary" | "accent";
type ButtonSizes = "md" | "lg";

interface ButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  kind: ButtonKinds;
  size: ButtonSizes;
}

export function Button(props: ButtonProps) {
  const { kind, size, ...rest } = props;

  const styles = useMemo(() => createStyles(kind, size), [kind, size]);

  return <button css={styles.button} {...rest} />;
}

const createStyles = (kind: ButtonKinds, size: ButtonSizes) => ({
  button: css`
    color: ${theme.pallete.surface.white};
    font-size: 16px;
    white-space: nowrap;
    border-radius: 50px;
    background: ${kind === "primary"
      ? theme.pallete.primary.main
      : theme.pallete.accent.main};
    padding: ${size === "lg" ? "14px 48px" : "12px 30px"};
    outline: none;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2 ease-in-out;
    &:hover {
      background: ${kind === "primary"
        ? theme.pallete.primary.dark
        : theme.pallete.accent.dark};
    }
  `,
});
