import { Fragment, useEffect } from "react";

import { NewsSection } from "./components/NewsSection";
import { HeroSection } from "./components/HeroSection";
import { EnredoSection } from "./components/EnredoSection";
import {
  aboutSectionData,
  discoverSectionData,
  signUpSectionData,
} from "./content";
import InfoSection from "./components/InfoSection";
import { Layout } from "../../components/Layout/Layout";
import { Helmet } from "react-helmet";

export const HomePage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Fragment>
      <Helmet title="Knives" defaultTitle="Knives" />
      <Layout
        showMenuLinks
        showGalleryButton
        showSignInButton
        showLinkToHome={false}
      >
        <Fragment>
          <HeroSection />
          <InfoSection {...aboutSectionData} />
          <InfoSection {...discoverSectionData} />
          <EnredoSection />
          <NewsSection /> {/** Novidades Section */}
          <InfoSection {...signUpSectionData} />
        </Fragment>
      </Layout>
    </Fragment>
  );
};
