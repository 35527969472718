/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useState } from "react";
import { Footer } from "../Footer";
import { Navbar } from "../Navbar";
import { Sidebar } from "../Sidebar";
import { theme } from "../../theme/theme";

interface Props {
  children: JSX.Element;
  showGalleryButton?: boolean;
  showSignInButton?: boolean;
  showLinkToHome?: boolean;
  showMenuLinks?: boolean;
}

export function Layout(props: Props) {
  const {
    children,
    showGalleryButton,
    showSignInButton,
    showLinkToHome,
    showMenuLinks,
  } = props;

  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <div css={styles.body}>
      <Sidebar
        isOpen={isOpen}
        toggle={toggle}
        showMenuLinks={showMenuLinks}
        showGaleriaButton={showGalleryButton}
        showLinkToHome={showLinkToHome}
        showSignInButton={showSignInButton}
      />
      <Navbar
        toggle={toggle}
        showGalleryButton={showGalleryButton}
        showSignInButton={showSignInButton}
        showMenuLinks={showMenuLinks}
      />

      <div css={styles.content}>{children}</div>

      <Footer />
    </div>
  );
}

const styles = {
  body: css`
    background: ${theme.pallete.surface.background};
  `,
  content: css`
    padding-top: 80px;
    width: 100%;
  `,
};
