import enredo1 from "./images/enredo1.jpg";
import enredo2 from "./images/enredo2.jpg";
import enredo3 from "./images/enredo3.jpg";
import enredo4 from "./images/enredo4.jpg";
import enredo5 from "./images/enredo5.jpg";
import enredo6 from "./images/enredo6.jpg";
import { InfoSectionProps } from "./components/InfoSection";

// add alts
export const enredoSectionContent: { image: string }[] = [
  {
    image: enredo1,
  },
  {
    image: enredo2,
  },
  {
    image: enredo3,
  },
  {
    image: enredo4,
  },
  {
    image: enredo5,
  },
  {
    image: enredo6,
  },
];

export const aboutSectionData: InfoSectionProps = {
  id: "about",
  // lightTextDesc: true,
  lightBg: false,
  lightText: true,
  topLine: "Sobre",
  headline: "Knives e Hank",
  description:
    "Após o incidente em Faux City, Knives e Hank fogem para Underland onde se instalam em um prédio abandonado. Lá, Tio Hank conta que o prédio já pertenceu a ele, também diz que irá reformar e transformá-lo em um Pub novamente. Segundo ele, essa cidade é cheia desses Pubs, por aqui eles são a melhor forma de arranjar dinheiro, influências e bastante problema.",
  buttonLabel: "Saiba mais",
  imgStart: false,
  img: require("../../images/hankknives.svg").default,
  alt: "Car",
  dark: true,
  primary: true,
  darkText: false,
  dark2: false,
};

export const discoverSectionData: InfoSectionProps = {
  id: "discover",
  // lightTextDesc: false,
  lightBg: true,
  lightText: false,
  topLine: "Jogabilidade",
  headline: "Do jogo",
  description:
    "O jogador irá controlar e desenvolver a personagem Knives, seu personagem principal e inicialmente que fará parte da equipe de Hank. Irá receber missões para cumprir, trabalhar no pub e ser capaz de explorar as regiões da cidade com a Knives.",
  buttonLabel: "Saiba mais",
  imgStart: false,
  img: require("../../images/batalha2.svg").default,
  alt: "Car",
  dark: false,
  primary: false,
  darkText: true,
  dark2: false,
};

export const signUpSectionData: InfoSectionProps = {
  id: "signup",
  // lightTextDesc: true,
  lightBg: false,
  lightText: true,
  topLine: "Mapa",
  headline: "Underland",
  description:
    "Depois de passar por todas as mecânicas de combate, desenvolvimento de personagens e administração do Pub, a história levará Knives a assumir o comando da organização. A partir desse momento o jogador terá livre acesso a qualquer parte do mapa, podendo dominar Pubs rivais e acessar qualquer Pub que esteja sob seu controle.",
  buttonLabel: "Saiba mais",
  imgStart: false,
  img: require("../../images/mapa.svg").default,
  alt: "Car",
  dark: true,
  primary: true,
  darkText: false,
  dark2: false,
};
