/** @jsxImportSource @emotion/react */
import { SerializedStyles, css } from "@emotion/react";

type HeadingLevels = "display" | "1" | "2" | "3" | "4" | "5";

interface HeadingProps extends React.HTMLAttributes<HTMLHeadingElement> {
  level: HeadingLevels;
}

export function Heading(props: HeadingProps) {
  const { level, ...rest } = props;

  const Cmp = cmp[level];
  const style = styles[level];

  return <Cmp css={style} {...rest} />;
}

const styles: Record<HeadingLevels, SerializedStyles> = {
  display: css`
    font-size: 56px;
    line-height: 64px;
  `,
  "1": css`
    font-size: 48px;
    line-height: 56px;
  `,
  "2": css`
    font-size: 40px;
    line-height: auto;
  `,
  "3": css`
    font-size: 32px;
    line-height: auto;
  `,
  "4": css`
    font-size: 24px;
    line-height: auto;
  `,
  "5": css`
    font-size: 14px;
    font-style: bold;
    line-height: 20px;
  `,
};

const cmp: Record<HeadingLevels, React.ElementType> = {
  display: "h1",
  "1": "h1",
  "2": "h2",
  "3": "h3",
  "4": "h4",
  "5": "h5",
};
