/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import "./roadmap.css";
import { Fragment, useEffect } from "react";

import { roadmapContent } from "./content";
import { Layout } from "../../components/Layout/Layout";
import { theme } from "../../theme/theme";
import { Heading } from "../../components/Heading/Heading";
import top from "./images/top.svg";
import bg from "./images/background.png";
import { ReactComponent as WorkIcon } from "./work.svg";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { Helmet } from "react-helmet";

let notDoneStyles = { background: theme.pallete.primary.main };
let doneStyles = { background: theme.pallete.accent.main };

export const RoadmapPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Fragment>
      <Helmet title="Roadmap / Knives" defaultTitle="Knives" />
      <Layout showGalleryButton showSignInButton showMenuLinks={false}>
        <div css={styles.content}>
          <Heading level="display" css={styles.heading}>
            Roadmap
          </Heading>
          <p css={styles.lead}>
            Vislumbre o futuro: veja o que vem por aí
            <br />
            em atualizações e recursos planejados!
          </p>
          <div css={styles.timeline}>
            <div css={styles.timelineHeader}>
              <img src={top} alt="" />
              <div css={styles.line} />
            </div>
            <VerticalTimeline>
              {roadmapContent.map((item) => (
                <VerticalTimelineElement
                  key={item.id}
                  iconStyle={item.done ? doneStyles : notDoneStyles}
                  icon={<WorkIcon />}
                >
                  <div css={styles.timelineItemDate}>{item.date}</div>
                  <ul css={styles.timelineItemList}>
                    {item.items.map((listItem) => (
                      <li css={styles.timelineItemListItem}>{listItem}</li>
                    ))}
                  </ul>
                </VerticalTimelineElement>
              ))}
            </VerticalTimeline>
          </div>
        </div>
      </Layout>
    </Fragment>
  );
};

const styles = {
  content: css`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 40px;
    background-image: url(${bg});
    background-size: cover;
    background-position: right;
  `,
  timeline: css`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    @media screen and (max-width: 1169px) {
      align-items: flex-start;
      justify-content: flex-start;
    }
  `,
  timelineHeader: css`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    @media screen and (max-width: 1169px) {
      display: none;
    }
  `,
  heading: css`
    color: ${theme.pallete.surface.white};
    margin-bottom: 16px;
  `,
  lead: css`
    color: ${theme.pallete.surface.white};
    text-align: center;
    margin-bottom: 48px;
  `,
  line: css`
    width: 9.5px;
    height: 24px;
    background: black;
    border-left: 3px solid ${theme.pallete.surface.white};
    border-right: 3px solid ${theme.pallete.surface.white};
    margin-top: -2px;
    margin-left: -0.5px;
  `,
  timelineItemDate: css`
    color: ${theme.pallete.accent.main};
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 16px;
  `,
  timelineItemList: css`
    padding-left: 16px;
    margin-left: 0;
  `,
  timelineItemListItem: css`
    margin-bottom: 12px;
    &:last-child {
      margin-bottom: 0;
    }
  `,
};
