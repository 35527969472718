/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Link } from "react-router-dom";

import Icon1 from "../../../images/iconroad.svg";
import Icon2 from "../../../images/signin.svg";
import Icon3 from "../../../images/mais2.svg";
import { theme } from "../../../theme/theme";
import { flags } from "../../../flags/flags";

export const NewsSection = () => {
  return (
    <div id="services" css={styles.container}>
      <h1 css={styles.h1}>Novidades</h1>
      <div css={styles.wrapper}>
        <Link to="roadmap" css={styles.card}>
          <img src={Icon1} alt="Ícone de roadmap" css={styles.cardIcon} />
          <h2 css={styles.cardTitle}>Roadmap</h2>
          <p css={styles.cardParagraph}>Confira o que está por vir!</p>
        </Link>
        <Link to="sign" css={styles.card}>
          <img src={Icon2} alt="Ícone de faça parte" css={styles.cardIcon} />
          <h2 css={styles.cardTitle}>Faça parte</h2>
          <p css={styles.cardParagraph}>Garanta seu lugar nessa história!</p>
        </Link>
        {flags.CONTENT_NOT_FINISHED && (
          <Link to="roadmap" css={styles.card}>
            <img src={Icon3} alt="Link de narrativa" css={styles.cardIcon} />
            <h2 css={styles.cardTitle}>Narrativa</h2>
            <p css={styles.cardParagraph}>Saiba mais sobre essa história!</p>
          </Link>
        )}
      </div>
    </div>
  );
};

const styles = {
  container: css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: ${theme.pallete.surface.white};
    padding-top: 64px;
    padding-bottom: 72px;
  `,
  h1: css`
    font-size: 3rem;
    line-height: 1.1;
    font-weight: bold;

    color: ${theme.pallete.surface.background};
    margin-bottom: 64px;
    text-align: center;

    @media screen and (max-width: 480px) {
      font-size: 2rem;
    }
  `,
  wrapper: css`
    max-width: 1000px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr ${flags.CONTENT_NOT_FINISHED && "1fr"};
    align-items: center;
    grid-gap: 16px;
    padding: 0 50px;

    @media screen and (max-width: 1000px) {
      grid-template-columns: 1fr 1fr;
    }

    @media screen and (max-width: 768px) {
      grid-template-columns: 1fr;
      padding: 0 20px;
    }
  `,
  card: css`
    background: ${theme.pallete.primary.main};
    text-decoration: none;
    border-style: solid;
    border-width: 3px;
    border-color: ${theme.pallete.accent.main};
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: 30px;
    color: ${theme.pallete.surface.white};
    max-height: 340px;
    padding: 30px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    transition: all 0.2s ease-in-out;

    &:hover {
      transform: scale(1.02);
      transition: all 0.2 ease-in-out;
      cursor: pointer;
    }
  `,
  cardIcon: css`
    height: 160px;
    width: 160px;
    margin-bottom: 10px;
  `,
  cardTitle: css`
    font-size: 1.125rem;
    margin-bottom: 10px;
    font-weight: bold;
  `,
  cardParagraph: css`
    font-size: 1rem;
    font-family: "Raleway", sans-serif;
    font-weight: normal;
    text-align: center;
  `,
};
