/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Component } from "react";
import { theme } from "../../theme/theme";

import image1 from "./images/52941269169_8f72bfff6e_b.jpg";
import image2 from "./images/52932634685_5f24d0affd_b.jpg";
import image3 from "./images/52931650507_85891e257a_b.jpg";
import image4 from "./images/52932686373_c088676787_b.jpg";
import image5 from "./images/52932673558_991e4625ab_b.jpg";
import image6 from "./images/52953777949_88b4ed1fd8_b.jpg";
import image7 from "./images/52932631410_716353a8e0_b.jpg";
import image8 from "./images/52932683968_f6718a49bf_b.jpg";
import image9 from "./images/52932690098_980abb6fc7_b.jpg";
import image10 from "./images/52932637475_6586608676_b.jpg";

export class Gallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      photos: [
        {
          url: image1,
          sector: "Artes Conceituais",
        },
        {
          url: image2,
          sector: "Artes Conceituais",
        },
        {
          url: image3,
          sector: "Cenários das Aranhas",
        },
        {
          url: image4,
          sector: "Cenários das Aranhas",
        },
        {
          url: image5,
          sector: "Cenários dos Ratos",
        },
        {
          url: image6,
          sector: "Cenários dos Ratos",
        },
        {
          url: image7,
          sector: "Cenários das Baratas",
        },
        {
          url: image8,
          sector: "Cenários das Baratas",
        },
        {
          url: image9,
          sector: "Cenários dos Sapos",
        },
        {
          url: image10,
          sector: "Cenários dos Sapos",
        },
      ],
      windowWidth: window.innerWidth,
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleWindowResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowResize);
  }

  handleWindowResize = () => {
    this.setState({ windowWidth: window.innerWidth });
  };

  getImageSize() {
    const { windowWidth } = this.state;

    if (windowWidth >= 1680) {
      return {
        width: "820px",
        height: "auto",
      };
    } else if (windowWidth >= 1440) {
      return {
        width: "700px",
        height: "auto",
      };
    } else if (windowWidth >= 800) {
      return {
        width: "600px",
        height: "auto",
      };
    } else if (windowWidth >= 424) {
      return {
        width: "450px",
        height: "auto",
      };
    } else {
      return {
        width: "100%",
        height: "auto",
      };
    }
  }

  render() {
    const { photos } = this.state;
    const sectors = [...new Set(photos.map((photo) => photo.sector))];
    const imageSize = this.getImageSize();

    return (
      <div css={styles.container}>
        <div>
          <h2 css={styles.title}>Galeria de Imagens</h2>
          {sectors.map((sector) => (
            <div key={sector}>
              <h3 css={styles.text}>{sector}</h3>
              <div css={styles.galleryPhoto}>
                {photos
                  .filter((photo) => photo.sector === sector)
                  .map((photo, index) => (
                    <div key={index} css={styles.text}>
                      <img
                        src={photo.url}
                        alt={`${photo.sector} ${index + 1}`}
                        style={{
                          ...imageSize,
                          boxShadow: "0px 10px 10px rgba(0, 0, 0, 0.2)",
                          marginLeft: "10px",
                          marginBottom: "20px",
                          borderRadius: "30px",
                        }}
                        css={styles.galleryPhoto}
                      />
                    </div>
                  ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

const styles = {
  container: css`
    background: ${theme.pallete.surface.background};
    margin-top: 0;
  `,
  title: css`
    background: ${theme.pallete.surface.background};
    font-size: 3rem;
    font-weight: bold;
    color: white;
    text-align: center;
    padding-top: 40px;
    margin-bottom: 20px;
    @media (max-width: 768px) {
      font-size: 48px;
    }
  `,
  text: css`
    font-size: 2.125rem;
    font-weight: bold;
    color: white;
    text-align: center;
    margin-bottom: 30px;
    @media (max-width: 768px) {
      font-size: 24px;
    }
  `,
  galleryPhoto: css`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  `,
  imageContainer: css`
    flex-basis: calc(50% - 10px); /* 50% de largura menos o espaçamento */
    margin-bottom: 60px;
    display: flex;
    justify-content: center;

    img {
      width: 100%;
      height: auto;
      max-width: 820px;
      max-height: 820px;
      box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.2);
      border-radius: 30px;
    }
  `,
};
