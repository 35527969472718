/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Link } from "react-router-dom";
import imgHeroBg from "../images/home-hero-bg.png";
import { theme } from "../../../theme/theme";

export const HeroSection = () => {
  return (
    <div css={styles.container}>
      <div css={styles.bg}>
        <img alt="Foto plana da cidade" src={imgHeroBg} css={styles.bgImage} />
      </div>
      <div css={styles.content}>
        <h1 css={styles.title}>KNIVES</h1>
        <p css={styles.paragraph}>Confira o desenvolvimento do jogo.</p>
        <div css={styles.btnWrapper}>
          <nav
            css={css`
              display: flex;
              align-items: center;
            `}
          >
            <Link to="galeria" css={styles.link}>
              Galeria de imagens
            </Link>
          </nav>
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: css`
    background: #0c0c0c;
    display: flex;
    justify-content: center;
    padding: 0 30px;
    position: relative;
    z-index: 1;
    height: calc(100vh - 80px);
    :before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 2;
    }
  `,
  bg: css`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: calc(100vh - 80px);
    overflow: hidden;
  `,
  bgImage: css`
    width: 100%;
    height: 100%;
    animation: zoom-in-zoom-out 5s ease infinite;

    @keyframes zoom-in-zoom-out {
      0% {
        transform: scale(1, 1);
      }
      50% {
        transform: scale(1.125, 1.125);
      }
      100% {
        transform: scale(1, 1);
      }
    }
  `,
  content: css`
    z-index: 3;
    max-width: 1200px;
    position: absolute;
    padding: 100px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  title: css`
    margin-top: 50%;
    color: ${theme.pallete.surface.white};
    font-size: 3rem;
    font-weight: bold;
    text-align: center;
    text-shadow: 2px 2px 5px black;
    @media screen and (max-width: 768px) {
      font-size: 40px;
    }
    @media screen and (max-width: 480px) {
      font-size: 32px;
    }
  `,
  paragraph: css`
    margin-top: 24px;
    color: ${theme.pallete.surface.white};
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    max-width: 600px;
    text-shadow: 2px 2px 5px black;
    @media screen and (max-width: 768px) {
      font-size: 24px;
    }
    @media screen and (max-width: 480px) {
      font-size: 18px;
    }
  `,
  btnWrapper: css`
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  link: css`
    border-radius: 50px;
    background: ${theme.pallete.primary.main};
    white-space: nowrap;
    padding: 15px 26px;
    color: ${theme.pallete.surface.white};
    font-size: 16px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;

    &:hover {
      transition: all 0.2 ease-in-out;
      background: #270042;
      color: ${theme.pallete.surface.white};
    }
  `,
};
