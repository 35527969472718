/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useEffect } from "react";
import {
  SidebarContainer,
  Icon,
  CloseIcon,
  SidebarWrapper,
  SidebarMenu,
  SidebarLinkS,
  SidebarLinkR,
  SideBtnWrap,
  SidebarRoute,
  LogoMenu,
} from "./SidebarElements";

interface Props {
  isOpen: boolean;
  toggle: () => void;
  showLinkToHome?: boolean;
  showMenuLinks?: boolean;
  showGaleriaButton?: boolean;
  showSignInButton?: boolean;
}

export const Sidebar = (props: Props) => {
  const {
    isOpen,
    toggle,
    showLinkToHome = true,
    showMenuLinks = true,
    showGaleriaButton = true,
    showSignInButton = true,
  } = props;

  useEffect(() => {
    if (isOpen) {
      document.getElementsByTagName("body")[0].style.overflow = "hidden";
    } else {
      document.getElementsByTagName("body")[0].style.overflow = "auto";
    }
  }, [isOpen]);

  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>
      <SidebarWrapper>
        {showLinkToHome && (
          <LogoMenu>
            <SidebarLinkS to="/" onClick={toggle}>
              Knives
            </SidebarLinkS>
          </LogoMenu>
        )}

        {showMenuLinks ? (
          <SidebarMenu>
            <SidebarLinkS to="about" onClick={toggle}>
              Sobre
            </SidebarLinkS>
            <SidebarLinkS to="discover" onClick={toggle}>
              Jogabilidade
            </SidebarLinkS>
            <SidebarLinkS to="arquetipos" onClick={toggle}>
              Enredo
            </SidebarLinkS>
            <SidebarLinkS to="services" onClick={toggle}>
              Novidades
            </SidebarLinkS>
            <SidebarLinkS to="signup" onClick={toggle}>
              Mapa
            </SidebarLinkS>
            <SidebarLinkR to="characters" onClick={toggle}>
              Personagens
            </SidebarLinkR>
          </SidebarMenu>
        ) : (
          <div
            css={css`
              height: 24px;
            `}
          />
        )}

        {showGaleriaButton && (
          <SideBtnWrap>
            <SidebarRoute to="/galeria">Galeria de imagens</SidebarRoute>
          </SideBtnWrap>
        )}

        {showSignInButton && (
          <SideBtnWrap>
            <SidebarRoute to="/signin">Registre-se</SidebarRoute>
          </SideBtnWrap>
        )}
      </SidebarWrapper>
    </SidebarContainer>
  );
};
