/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useRef, useEffect } from "react";
import { Link } from "react-router-dom";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
import { theme } from "../../../theme/theme";
import { flags } from "../../../flags/flags";

gsap.registerPlugin(ScrollTrigger);

export interface InfoSectionProps {
  id: string;
  lightBg: boolean;
  imgStart: boolean;
  topLine: string;
  lightText: boolean;
  headline: string;
  darkText: boolean;
  description: string;
  buttonLabel: string;
  img: string;
  alt: string;
  primary: boolean;
  dark: boolean;
  dark2: boolean;
}

export const InfoSection = (props: InfoSectionProps) => {
  const {
    id,
    lightBg,
    imgStart,
    topLine,
    lightText,
    headline,
    darkText,
    description,
    buttonLabel,
    img,
    alt,
    // primary,
    // dark,
    // dark2,
  } = props;

  const imgRef = useRef(null);
  const contentRef = useRef(null);

  useEffect(() => {
    const el = imgRef.current;
    gsap.fromTo(
      el,
      { x: "30", opacity: 0 },
      {
        x: 0,
        opacity: 1,
        duration: 1,
        scrollTrigger: {
          trigger: el,
        },
      }
    );
  }, []);

  useEffect(() => {
    const el = contentRef.current;
    gsap.fromTo(
      el,
      { opacity: 0 },
      {
        opacity: 1,
        duration: 1.5,
        scrollTrigger: {
          trigger: el,
        },
      }
    );
  }, []);

  return (
    <>
      <div id={id} css={styles.container(lightBg)}>
        <div css={styles.wrapper}>
          <div css={styles.row(imgStart)}>
            <div css={styles.col1}>
              <div
                className="content"
                ref={contentRef}
                css={styles.textWrapper}
              >
                <p css={styles.topLine}>{topLine}</p>
                <h1 css={styles.heading(lightText)}>{headline}</h1>
                <p css={styles.subtitle(darkText)}>{description}</p>
                {flags.CONTENT_NOT_FINISHED && (
                  <div css={styles.btnWrapper}>
                    <Link
                      to="Saiba"
                      // duration={500}
                      // exact={true}
                      // offset={-80}
                      // primary={primary ? 1 : 0}
                      // dark={dark ? 1 : 0}
                      // dark2={dark2 ? 1 : 0}
                      css={styles.heroBtnLink}
                    >
                      {buttonLabel}
                    </Link>
                  </div>
                )}
              </div>
            </div>
            <div css={styles.col2}>
              <div css={styles.imageWrapper}>
                <img src={img} alt={alt} ref={imgRef} css={styles.image} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const styles = {
  container: (lightBg: boolean) => css`
    color: ${theme.pallete.surface.white};
    height: 900px;
    background: ${lightBg ? "#EFEFEF" : theme.pallete.surface.background};
    @media screen and (max-width: 1000px) {
      height: 1100px;
      padding-top: 100px;
    }
  `,
  wrapper: css`
    display: grid;
    justify-content: center;
    width: 100%;
    max-width: 1200px;
    height: 860px;
    margin-right: auto;
    margin-left: auto;
    padding: 0 24px;
    z-index: 1;
  `,
  row: (imgStart: boolean) => css`
    display: grid;
    grid-auto-columns: minmax(auto, 1fr);
    align-items: center;
    grid-template-areas: ${imgStart ? `'col2 col1'` : `'col1 col2'`};

    @media screen and (max-width: 1000px) {
      grid-template-areas: ${imgStart
        ? `'col1' 'col2'`
        : `'col1 col1' 'col2 col2'`};
    }
  `,
  col1: css`
    margin-bottom: 15px;
    padding: 0 15px;
    grid-area: col1;
  `,
  col2: css`
    margin-bottom: 15px;
    padding: 0 15px;
    grid-area: col2;
  `,
  textWrapper: css`
    max-width: 600px;
    padding-top: 0;
    padding-bottom: 60px;
  `,
  topLine: css`
    color: ${theme.pallete.accent.main};
    font-size: 1rem;
    line-height: 16px;
    font-weight: 700;
    letter-spacing: 1.4px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 16px;
  `,
  heading: (lightText: boolean) => css`
    color: ${lightText ? "#f7f8fa" : "#010606"};
    font-size: 3rem;
    line-height: 1.1;
    font-weight: bold;
    margin-bottom: 24px;
    @media screen and (max-width: 480px) {
      font-size: 32px;
    }
  `,
  subtitle: (darkText: boolean) => css`
    max-width: 600px;
    margin-bottom: 35px;
    font-size: 1.125rem;
    line-height: 1.5rem;
    font-weight: normal;
    font-family: "Raleway", sans-serif;
    color: ${darkText ? "#010606" : theme.pallete.surface.white};
  `,
  btnWrapper: css`
    display: flex;
    justify-content: flex-start;
  `,
  heroBtnLink: css`
    border-radius: 50px;
    background: ${theme.pallete.primary.main};
    white-space: nowrap;
    padding: 15px 26px;
    color: ${theme.pallete.surface.white};
    font-size: 1rem;

    font-weight: normal;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;

    &:hover {
      transition: all 0.2 ease-in-out;
      background: #270042;
      color: ${theme.pallete.surface.white};
    }
  `,
  imageWrapper: css`
    max-width: 600px;
    height: 100%;

    @media screen and (max-width: 1000px) {
      max-width: 100%;
    }
  `,
  image: css`
    width: 600px;
    margin: 0 0 0px 0;
    padding-right: 0;

    @media screen and (max-width: 1000px) {
      max-width: 100%;
    }
  `,
};

export default InfoSection;
