import {
  CodeResponse,
  googleLogout,
  useGoogleLogin,
} from "@react-oauth/google";
import axios from "axios";
import { useState, useEffect } from "react";

interface UseInternalGoogleLoginProps {
  onSuccess: () => void;
  onError: (
    errorResponse: Pick<
      CodeResponse,
      "error" | "error_description" | "error_uri"
    >
  ) => void;
  onFinally: () => void;
}

interface UseInternalGoogleLoginResponse {
  login: () => void;
  logout: () => void;
}

export function useInternalGoogleLogin(
  props: UseInternalGoogleLoginProps
): UseInternalGoogleLoginResponse {
  const { onSuccess, onError, onFinally } = props;

  const [user, setUser] = useState<any>();

  const googleLogin = useGoogleLogin({
    onSuccess: (response) => setUser(response),
    onError,
  });

  useEffect(() => {
    if (user) {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then((res: any) => {
          onSuccess();
        })
        .catch(onError)
        .finally(onFinally);
    }
  }, [onError, onFinally, onSuccess, user]);

  return {
    login: googleLogin,
    logout: googleLogout,
  };
}
