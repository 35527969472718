import styled from "styled-components";
import { theme } from "../../theme/theme";

export const InfoContainer = styled.div`
  color: ${theme.pallete.surface.white};
  height: 1000px;
  background: ${theme.pallete.surface.background};
  background-image: url("./images/pexels-photo-3615180.jpeg");
  background-repeat: no-repeat;
  background-size: cover;

  @media screen and (max-width: 768px) {
    padding: 100px 0;
  }
`;

export const InfoContainer2 = styled.div`
  color: ${theme.pallete.surface.white};
  height: 900px;
  background: ${theme.pallete.surface.background};

  @media screen and (max-width: 768px) {
    padding: 100px 0;
  }
`;

export const InfoWrapper = styled.div`
  display: grid;
  z-index: 1;
  max-width: 1100px;
  height: 860px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding: 0 24px;
  justify-content: center;
`;

export const InfoRow = styled.div`
  display: grid;
  grid-auto-columns: minmax(auto, 1fr);
  align-items: center;
  grid-template-areas: ${({ imgStart }: { imgStart: boolean }) =>
    imgStart ? `'col2 col1'` : `'col1 col2'`};

  @media screen and (max-width: 768px) {
    grid-template-areas: ${({ imgStart }: { imgStart: boolean }) =>
      imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`};
  }
`;

export const Colum2 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col1;
`;

export const Colum1 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col2;
`;

export const TextWrapper = styled.div`
  max-width: 540px;
  padding-top: 0;
  padding-bottom: 60px;
`;

export const TopLine = styled.p`
  color: ${theme.pallete.accent.main};
  font-size: 1rem;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;
  font-weight: bold;

  text-transform: uppercase;
  margin-bottom: 16px;
`;

export const Heading = styled.h1`
  margin-bottom: 24px;
  font-size: 3rem;
  line-height: 1.1;
  font-weight: bold;

  color: "#f7f8fa";

  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`;

export const Subtitle = styled.p`
  max-width: 480px;
  margin-bottom: 35px;
  font-size: 1.125rem;
  line-height: 1.5rem;
  font-weight: normal;
  font-family: "Raleway", sans-serif;
  color: "#010606";
`;

export const ImgWrap = styled.div`
  max-width: 600px;
  height: 100%;
  padding-top: 100px;
`;

export const Img = styled.img`
  width: 600px;
  margin: 0 0 0px 0;
  padding-right: 0;
`;
