export const theme = {
  pallete: {
    primary: {
      light: "#763EA0",
      main: "#4B0082",
      dark: "#270042",
    },
    accent: {
      light: "#F8B96D",
      main: "#F29422",
      dark: "#C26D06",
    },
    surface: {
      background: "#181818",
      white: "#FFFFFF",
      black: "#000000",
    },
  },
};
