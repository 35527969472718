export const roadmapContent: {
  id: number;
  items: string[];
  date: string;
  done: boolean;
}[] = [
  {
    id: 1,
    date: "06/2023",
    items: ["Criação do site", "Desenvolvimento do conceito"],
    done: true,
  },
  {
    id: 2,
    date: "12/2023",
    items: [
      "Desenvolvimento do beta fechado",
      "Criação de uma fanbase ativa através das redes sociais",
      "Pacotes de Parceria",
      "Crowdfunding",
    ],
    done: true,
  },

  {
    id: 3,
    items: [
      "Desenvolvimento do jogo aberto em Live",
      "Fórum",
      "Premiações para Parceiros e Apoiadores",
      "Eventos nas redes sociais",
    ],
    date: "06/2024",
    done: true,
  },
  {
    id: 4,
    date: "12/2024",
    items: [
      "Lançamento do jogo com todas as funcionalidades (exploração, batalha, crafting, dominação, dentre outros)",
      "Eventos com premiação para jogadores",
      "Apoio a criadores de conteúdo",
      "Eventos nas redes sociais",
    ],
    done: false,
  },
];
