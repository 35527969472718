/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useState, useEffect, Fragment } from "react";
import charSectionBg from "./images/layout/char-section-bg.png";
import { characters } from "./../characters/content";
import { Layout } from "../../components/Layout/Layout";
import { theme } from "../../theme/theme";
import { Helmet } from "react-helmet";

export function CharactersPage() {
  const [activeCharacter, setActiveCharacter] = useState(characters[0]);
  const [activeSkill, setActiveSkill] = useState(activeCharacter.skills[0]);

  const indexOfActiveSkill = activeCharacter.skills.indexOf(activeSkill);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Fragment>
      <Helmet title="Personagens / Knives" defaultTitle="Knives" />
      <Layout showGalleryButton showSignInButton showMenuLinks={false}>
        <div css={styles.pageContainer}>
          <section css={styles.charactersSection}>
            <div css={[styles.container, styles.charactersContainer]}>
              <ul css={styles.charactersList}>
                {characters.map((character) => (
                  <li
                    key={character.slug}
                    css={styles.charactersListItem(
                      character.slug === activeCharacter.slug
                    )}
                  >
                    <button onClick={() => setActiveCharacter(character)}>
                      <img
                        src={require(`./images/${character.slug}/icon.png`)}
                        alt={"Ícone de " + character.name}
                      />

                      <span>{character.name}</span>
                    </button>
                  </li>
                ))}
              </ul>

              <img
                src={require(`./images/${activeCharacter.slug}/main.png`)}
                alt={activeCharacter.name}
                css={[styles.characterImage, activeCharacter.imageCss]}
              />

              <div css={styles.characterCard}>
                <hr css={styles.characterCardHr} />

                <h2 css={styles.characterCardTitle}>{activeCharacter.role}</h2>

                <h3 css={styles.characterCardSubtitle}>Descrição</h3>

                <p css={styles.characterCardParagraph}>
                  {activeCharacter.description}
                </p>
              </div>
            </div>
          </section>
          <section css={styles.skillsSection}>
            <div css={styles.skillsContainer}>
              <div css={styles.skillsContent}>
                <h2 css={styles.skillsContentTitle}>Skills</h2>

                <ul css={styles.skillsContentList}>
                  {activeCharacter.skills.map((skill, index) => {
                    const indexOfSkill = activeCharacter.skills.indexOf(skill);

                    return (
                      <li
                        css={styles.skillsContentListItem}
                        key={`${skill.title}${index}`}
                      >
                        <button onClick={() => setActiveSkill(skill)}>
                          <img
                            src={skill.icon}
                            // src={require(
                            //   `./images/${activeCharacter.slug}/skill${
                            //     indexOfSkill + 1
                            //   }.png`
                            // )}
                            alt={"Ícone de " + skill.title}
                            css={styles.skillsContentListItemImage(
                              indexOfSkill === indexOfActiveSkill
                            )}
                          />
                        </button>
                      </li>
                    );
                  })}
                </ul>

                <h4 css={styles.skillsContentSubtitle}>{activeSkill.title}</h4>

                <p css={styles.skillsContentDescription}>
                  {activeSkill.description}
                </p>
              </div>

              <img
                src={activeSkill.image}
                // src={require(
                //   `./images/${activeCharacter.slug}/skill${
                //     indexOfActiveSkill + 1
                //   }-main.png`
                // )}
                alt={activeSkill.title}
                css={styles.skillsCover}
              />
            </div>
          </section>
        </div>
      </Layout>
    </Fragment>
  );
}

const styles = {
  pageContainer: css`
    background: ${theme.pallete.surface.background};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `,
  container: css`
    width: 100%;
    padding: 0 24px;
    max-width: 1300px;
  `,
  charactersSection: css`
    width: 100%;
    height: 620px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    background-image: url(${charSectionBg});
    position: relative;

    @media screen and (max-width: 1023px) {
      height: auto;
    }
  `,
  charactersContainer: css`
    width: 1300px;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 1023px) {
      width: 100%;
      flex-direction: column;
      padding: 0;
    }
  `,
  charactersList: css`
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    position: relative;
    padding: 16px 0;
    padding-right: 8px;
    ::-webkit-scrollbar {
      width: 6px;
      margin-top: 10px;
    }
    ::-webkit-scrollbar-track {
      background: transparent;
    }
    ::-webkit-scrollbar-thumb {
      background: rgba(255, 255, 255, 0.2);
      border-radius: 10px;

      &:hover {
        background: #555;
      }
    }
    @media screen and (max-width: 1023px) {
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: row;
      overflow-y: hidden;
      overflow-x: scroll;
      padding-top: 32px;
      padding-bottom: 8px;
      margin-bottom: 8px;
      width: 100%;
    }
  `,
  charactersListItem: (active: boolean) => css`
    margin: 16px 0;

    button {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: transparent;
      border: 0;
      cursor: pointer;

      img {
        width: ${active ? 144 : 72}px;
        height: ${active ? 144 : 72}px;
        margin-bottom: 16px;
        filter: grayscale(${active ? 0 : 1});
      }

      span {
        color: ${theme.pallete.surface.white};
        font-size: ${active ? 22 : 16}px;
        font-weight: 600;
      }
    }

    @media screen and (max-width: 1023px) {
      margin: 0 16px;
      flex: 1;

      button {
        img {
          width: 72px;
          height: 72px;
        }
      }
    }
  `,
  characterImage: css`
    position: absolute;

    @media screen and (max-width: 1023px) {
      position: static;
      width: 50%;
    }
  `,
  characterCard: css`
    width: 500px;
    background: ${theme.pallete.surface.background};
    padding: 56px;

    @media screen and (max-width: 1023px) {
      width: 100%;
      padding: 32px;
    }
  `,
  characterCardHr: css`
    width: 145px;
    height: 4px;
    background: ${theme.pallete.accent.main};
    margin-bottom: 24px;
    border: 0;
  `,
  characterCardTitle: css`
    color: ${theme.pallete.surface.white};
    margin-bottom: 16px;
  `,
  characterCardSubtitle: css`
    color: ${theme.pallete.surface.white};
    margin-bottom: 16px;
  `,
  characterCardParagraph: css`
    color: ${theme.pallete.surface.white};
    line-height: 1.25rem;
  `,
  skillsSection: css`
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 72px;
    padding-bottom: 80px;
    width: 100%;
  `,
  skillsContainer: css`
    width: 100%;
    padding: 0 24px;
    max-width: 1300px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    @media screen and (max-width: 1023px) {
      flex-direction: column;
    }
  `,
  skillsContent: css`
    display: flex;
    flex-direction: column;
    width: 100%;

    @media screen and (max-width: 1023px) {
      margin-bottom: 32px;
    }
  `,
  skillsContentTitle: css`
    color: ${theme.pallete.surface.white};
    font-size: 40px;
    margin-bottom: 40px;
  `,
  skillsContentList: css`
    list-style: none;
    display: flex;
    margin-bottom: 32px;
  `,
  skillsContentListItem: css`
    margin-right: 24px;

    &:last-child {
      margin-right: 0;
    }

    button {
      width: 64px;
      height: 64px;
      background: transparent;
      border: 0;
      cursor: pointer;
    }
  `,
  skillsContentListItemImage: (active: boolean) => css`
    width: 64px;
    height: 64px;
    transition: all 0.2 ease-in-out;
    filter: grayscale(${active ? 0 : 1});
    &:hover {
      filter: grayscale(0);
    }
  `,
  skillsContentSubtitle: css`
    color: ${theme.pallete.surface.white};
    font-size: 24px;
    margin-bottom: 16px;
  `,
  skillsContentDescription: css`
    color: ${theme.pallete.surface.white};
    font-size: 16px;
    line-height: 22px;
    width: 360px;
  `,
  skillsCover: css`
    width: 670px;

    @media screen and (max-width: 1023px) {
      width: 100%;
    }
  `,
};
