/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useState, useEffect } from "react";
import { FaBars } from "react-icons/fa";
import { IconContext } from "react-icons/lib";
import { Link as LinkScroll, animateScroll as scroll } from "react-scroll";
import {
  Nav,
  NavbarContainer,
  NavbarButtons,
  NavLogo,
  MobileIcon,
  NavMenu,
  NavItem,
  NavBtn,
  NavBtnLink,
} from "./NavbarElements";
import { theme } from "../../theme/theme";
import { Link } from "react-router-dom";

interface Props {
  showMenuLinks?: boolean;
  showGalleryButton?: boolean;
  showSignInButton?: boolean;
  toggle: () => void;
}

export const Navbar = ({
  toggle,
  showMenuLinks,
  showGalleryButton,
  showSignInButton,
}: Props) => {
  const [scrollNav, setScrollNav] = useState(false);

  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeNav);
  }, []);

  const toggleHome = () => {
    scroll.scrollToTop();
  };

  return (
    <>
      <IconContext.Provider value={{ color: "#fff" }}>
        <Nav scrollNav={scrollNav}>
          <NavbarContainer>
            <NavLogo to="/" onClick={toggleHome}>
              Knives
            </NavLogo>
            <MobileIcon onClick={toggle}>
              <FaBars />
            </MobileIcon>
            {showMenuLinks && (
              <NavMenu>
                <NavItem>
                  <LinkScroll
                    to="about"
                    smooth
                    duration={500}
                    spy={true}
                    offset={-80}
                    css={styles.navLink}
                  >
                    Sobre
                  </LinkScroll>
                </NavItem>
                <NavItem>
                  <LinkScroll
                    to="discover"
                    smooth
                    duration={500}
                    spy={true}
                    offset={-80}
                    css={styles.navLink}
                  >
                    Jogabilidade
                  </LinkScroll>
                </NavItem>
                <NavItem>
                  <LinkScroll
                    to="arquetipos"
                    smooth
                    duration={500}
                    spy={true}
                    offset={-80}
                    css={styles.navLink}
                  >
                    Enredo
                  </LinkScroll>
                </NavItem>
                <NavItem>
                  <LinkScroll
                    to="services"
                    smooth
                    duration={500}
                    spy={true}
                    offset={-80}
                    css={styles.navLink}
                  >
                    Novidades
                  </LinkScroll>
                </NavItem>
                <NavItem>
                  <LinkScroll
                    to="signup"
                    smooth
                    duration={500}
                    spy={true}
                    offset={-80}
                    css={styles.navLink}
                  >
                    Mapa
                  </LinkScroll>
                </NavItem>
                <NavItem>
                  <Link to="characters" css={styles.navLink}>
                    Personagens
                  </Link>
                </NavItem>
              </NavMenu>
            )}

            <NavbarButtons>
              {showGalleryButton && (
                <NavBtn>
                  <NavBtnLink to="/galeria">Galeria de imagens</NavBtnLink>
                </NavBtn>
              )}

              {showSignInButton && (
                <NavBtn>
                  <NavBtnLink to="/signin">Registre-se</NavBtnLink>
                </NavBtn>
              )}
            </NavbarButtons>
          </NavbarContainer>
        </Nav>
      </IconContext.Provider>
    </>
  );
};

const styles = {
  navLink: css`
    color: ${theme.pallete.surface.white};
    display: flex;
    align-items: center;
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: 0.4px;
    text-decoration: none;
    padding: 0 1rem;
    height: 80px;
    cursor: pointer;

    &.active {
      border-bottom: 3px solid ${theme.pallete.accent.main};
    }
  `,
};
