import { useEffect, Fragment } from "react";
import {
  InfoContainer,
  // InfoContainer2,
  InfoWrapper,
  InfoRow,
  Colum1,
  Colum2,
  TextWrapper,
  TopLine,
  Heading,
  Subtitle,
  ImgWrap,
  Img,
} from "../../pages/know-more/SaibaElements";
import image1 from "./images/52955941127_348a603be8_b.jpg";
// import image2 from "./images/52956990593_801161c5f2_b.jpg";
import { Layout } from "../../components/Layout/Layout";
import { Helmet } from "react-helmet";

interface Props {
  imgStart?: boolean;
}

export const KnowMorePage = (props: Props) => {
  const { imgStart } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Fragment>
      <Helmet title="Em breve / Knives" defaultTitle="Knives" />
      <Layout showGalleryButton showSignInButton showMenuLinks={false}>
        <Fragment>
          <InfoContainer>
            <InfoWrapper>
              <InfoRow imgStart={imgStart}>
                <Colum1>
                  <TextWrapper>
                    <TopLine>Em breve</TopLine>
                    <Heading>Título da seção</Heading>
                    <Subtitle>Descrição da seção</Subtitle>
                  </TextWrapper>
                </Colum1>
                <Colum2>
                  <ImgWrap>
                    <Img src={image1} alt="Imagem" />
                  </ImgWrap>
                </Colum2>
              </InfoRow>
            </InfoWrapper>
          </InfoContainer>

          {/* <InfoContainer2>
          <InfoWrapper>
            <InfoRow>
              <Colum1>
                <TextWrapper>
                  <TopLine>Em breve</TopLine>
                  <Heading>Título da seção</Heading>
                  <Subtitle>Descrição da seção</Subtitle>
                </TextWrapper>
              </Colum1>
              <Colum2>
                <ImgWrap>
                  <Img src={image2} alt="Outra Imagem" />
                </ImgWrap>
              </Colum2>
            </InfoRow>
          </InfoWrapper>
        </InfoContainer2> */}
        </Fragment>
      </Layout>
    </Fragment>
  );
};
