/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Carousel } from "react-carousel-minimal";
import { enredoSectionContent } from "../content";
import { theme } from "../../../theme/theme";

export function EnredoSection() {
  return (
    <div id="arquetipos" css={styles.container}>
      <div
        style={{
          textAlign: "center",
          color: theme.pallete.surface.white,
          padding: "20px",
        }}
      >
        <h1 css={styles.h1}>Enredo</h1>
        <div>
          <Carousel
            data={enredoSectionContent}
            time={2000}
            width="100%"
            height="100%"
            captionStyle={{
              fontSize: "3rem",
              fontWeight: "bold",
            }}
            radius="10px"
            slideNumber={false}
            slideNumberStyle={{
              fontSize: "1.25rem",
              fontWeight: "bold",
            }}
            captionPosition="bottom"
            automatic={false}
            dots={true}
            pauseIconColor="white"
            pauseIconSize="40px"
            slideBackgroundColor="darkgrey"
            slideImageFit="cover"
            thumbnails={false}
            thumbnailWidth="100px"
            style={{
              textAlign: "center",
              maxWidth: "1100px",
              maxHeight: "100%",
              width: "100%",
              height: "100%",
              margin: "auto",
            }}
          />
        </div>
      </div>
    </div>
  );
}

const styles = {
  container: css`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: ${theme.pallete.surface.background};
    padding-top: 64px;
    padding-bottom: 72px;
  `,
  h1: css`
    font-size: 3rem;
    line-height: 1.1;
    font-weight: bold;
    margin-top: 10px;
    color: ${theme.pallete.surface.white};
    text-align: center;
    margin-bottom: 32px;

    @media screen and (max-width: 1200px) {
      font-size: 34px;
    }

    @media screen and (max-width: 748px) {
      font-size: 24px;
    }
  `,
};
