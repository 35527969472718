import { Modal } from "react-responsive-modal";
import "./registerModal.css";
import { Button } from "../../../components/Button/Button";

interface RegisterModalProps {
  isOpen: boolean;
  type: "success" | "error";
  onClose: () => void;
}

export function RegisterModal(props: RegisterModalProps) {
  const { isOpen, type, onClose } = props;

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      center
      classNames={{
        modal: "customModal",
      }}
    >
      <div>
        <h2>
          {type === "success"
            ? "E-mail registrado com sucesso!"
            : "Erro ao registrar seu email."}
        </h2>
        {type === "success" && (
          <p>
            Obrigado por fazer parte da comunidade!
            <br /> Seu email foi registrado para ficar ligado nas novidades!
          </p>
        )}
        {type === "error" && (
          <p>
            Ocorreu um erro ao registrar seu e-mail.
            <br /> Por favor tente mais tarde!
          </p>
        )}
        <Button kind="primary" size="md" onClick={onClose}>
          Fechar
        </Button>
      </div>
    </Modal>
  );
}
